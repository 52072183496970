import store from '@/store'
import { ILogV2 } from '@/types/resources-version-2'
import { get } from 'lodash-es'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getAllKeysFromObject = (obj: any, prefix = ''): string[] => Object.keys(obj)
  .reduce((res: string[], el: string) => {
    if (Array.isArray(obj[el])) {
      return [...res, prefix + el]
    }
    if (typeof obj[el] === 'object' && obj[el] !== null) {
      return [...res, ...getAllKeysFromObject(obj[el], `${ prefix + el }.`)]
    }
    return [...res, prefix + el]
  }, [])

export const doesTranslationExist = (key: string): boolean => {
  const { translations } = store.getters
  return key && translations.hasOwnProperty(key)
}

export const doesListAlreadyIncludeItem = (
  key: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  list: { translation: string, [key:string]: any }[],
  ommitID = '',
  field = 'translation',
): boolean => !!list.find(
  (x) => x[field] === key && (!ommitID || x.id !== ommitID),
)

export const formatEWCCode = (ewcCode: string): string => {
  const codeArray = ewcCode.split('')
  return codeArray.map(
    (char, index) => ([1, 3].includes(index) ? `${ char } ` : char),
  ).join('')
}

// This is used across V2 and V3 resources and prices
export const updateLog = ({
  newItem,
  oldItem,
  isNew,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
}: { newItem: any, oldItem: any, isNew: boolean }): void => {
  const { currentUser } = store.getters
  const newLog: ILogV2 = {
    userID: currentUser.id,
    timestamp: Date.now(),
    changes: [],
  }

  if (isNew) {
    newItem.logs.push(newLog)
    return
  }

  // Find changes
  const keysStrings = getAllKeysFromObject(newItem)
  keysStrings.forEach((keyString) => {
    if (keyString === 'logs') {
      return
    }

    const newValue = get(newItem, keyString)
    const oldValue = get(oldItem, keyString)

    // Log diff when array
    if (Array.isArray(newValue)) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        newLog.changes.push({
          key: keyString,
          type: 'array',
        })
      }
      return
    }

    // Log diff when not array
    if (newValue !== oldValue) {
      newLog.changes.push({
        key: keyString,
        newValue: newValue ?? '',
        oldValue: oldValue ?? '',
      })
    }
  })

  // Don't log changes if no changes are detected
  if (!newLog.changes.length) {
    return
  }

  newItem.logs.push(newLog)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const copyObject = (obj: any): any => JSON.parse(JSON.stringify(obj))
